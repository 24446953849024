.Maskinput:hover {
    border: 1.5px solid #57275E; /* Border on hover */
  }
  
  .Maskinput:focus {
    border: 1.5px solid #57275E; 
    outline:none;
  }
  
  .Maskinput {
    width: 100%;
    height: 38px;
    padding: 9px 14px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    border-radius: 4px;
    border: 1.5px solid lightgrey;
    border-radius: "5px";
  }