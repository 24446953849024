.rec.rec-arrow {
  border-radius: 0;
  background-color: #f6f5f1;
  box-shadow: none;
  font-size: 1em;
}
.rec.rec-arrow:hover:enabled {
  color: black;
  box-shadow: none;
  background: none;
  border-radius: 50%;
}
.rec.rec-arrow:focus:enabled {
  color: black;
  box-shadow: none;
  background: none;
  border-radius: 50%;
}
.rec.rec-pagination {
  visibility: hidden;
}
.rec.rec-carousel-item {
  display: flex;
  align-items: center;
}
