*{

  margin: 0px;
  font-family: "Plus Jakarta Sans", sans-serif;
  box-sizing: border-box;

}

body {
  margin: 0;
  font-family: "Plus Jakarta Sans", sans-serif;
  box-sizing: border-box;
  padding: 0px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main {
  flex: 1;
  display: flex;
}
