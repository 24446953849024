.snackbar {
    width: 100%;
    position: unset !important;
    text-align: center;
    transform: none !important;
}

.smallScreen {
    height: 100%;
    top: 15%;
}

@media screen and (max-width: 600px) {
    .snackbar {
        left: 0 !important;
        transform: none !important;
    }
}